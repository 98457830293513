<template>
    <div class="container mt-5 detail-container">
        <!-- Day Title -->
        <h2 class="mb-4 detail-title">{{ day.title }}</h2>

        <!-- Tasks List -->
        <ul class="list-group mb-4 task-list">
            <li v-for="(task, index) in day.tasks" :key="index" class="list-group-item task-item">
                <h5>{{ task.title }}</h5>
                <p class="task-details">{{ task.details }}</p>
            </li>
        </ul>

        <!-- Add Reflection or Notes -->
        <textarea v-model="day.note" class="form-control note-input mb-4" placeholder="Add your reflection here"
            rows="5"></textarea>

        <!-- Navigation Back to Dashboard -->
        <router-link to="/" class="btn btn-outline-light btn-back">
            Back to Dashboard
        </router-link>
    </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { tasks as initialTasks } from "../tasks";

// Load tasks from localStorage or fallback to initial data
const tasks = reactive(
    JSON.parse(localStorage.getItem("tasks")) || initialTasks
);

// Get the current route and router instance
const route = useRoute();
const router = useRouter();

// Find the current day based on the route parameter
const day = tasks.find((task) => task.id === parseInt(route.params.id));

// Redirect to the home page if the day is not found
if (!day) {
    alert("Day not found!");
    router.push("/");
}

// Watch the tasks array and save to localStorage on any changes
watch(
    tasks,
    () => {
        localStorage.setItem("tasks", JSON.stringify(tasks));
    },
    { deep: true }
);
</script>

<style scoped>
/* Container Styling */
.detail-container {
    background-color: #1e1e1e;
    /* Dark grey */
    color: #ffffff;
    /* White text */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Title Styling */
.detail-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #f8f9fa;
    /* Off-white */
    border-bottom: 2px solid #4a4a4a;
    /* Subtle divider */
    padding-bottom: 10px;
}

/* Task List Styling */
.task-list {
    background-color: transparent;
    /* Transparent list */
    padding: 0;
}

.task-item {
    background-color: #2b2b2b;
    /* Darker grey */
    border: 1px solid #4a4a4a;
    /* Border */
    color: #ffffff;
    /* White text */
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
}

.task-item:hover {
    background-color: #3a3a3a;
    /* Slightly lighter on hover */
}

.task-details {
    font-size: 0.9rem;
    color: #d3d3d3;
    /* Light grey text for details */
}

/* Note Input Styling */
.note-input {
    background-color: #2b2b2b;
    /* Dark grey */
    border: 1px solid #4a4a4a;
    /* Grey border */
    color: #ffffff;
    /* White text */
}

.note-input::placeholder {
    color: #a0a0a0;
    /* Light grey placeholder */
}

/* Back Button Styling */
.btn-back {
    border-color: #6c757d;
    /* Light grey border */
    color: #ffffff;
    /* White text */
}

.btn-back:hover {
    background-color: #6c757d;
    /* Grey hover background */
    color: #000000;
    /* Black text */
}
</style>