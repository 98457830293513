<template>
    <div class="app-container">
        <router-view />
    </div>
</template>

<script setup>
console.log("App loaded");
</script>

<style scoped>
/* Global App Container Styling */
.app-container {
    background-color: #1e1e1e;
    /* Dark grey */
    color: #ffffff;
    /* White text */
    min-height: 100vh;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

/* Progress Bar Styling */
.progress {
    background-color: #2b2b2b;
    /* Darker grey for progress background */
    border-radius: 4px;
}

.progress-bar {
    background-color: #6c757d;
    /* Light grey for progress fill */
    color: #000000;
    /* Black text for contrast */
    font-weight: bold;
    text-align: center;
}

/* Card Styling (from DayCard.vue) */
.card {
    background-color: #2b2b2b;
    /* Darker grey for card background */
    border: 1px solid #4a4a4a;
    /* Grey border for subtle separation */
    color: #ffffff;
    /* White text */
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: scale(1.02);
    /* Slight zoom effect on hover */
    background-color: #3a3a3a;
    /* Lighter grey for hover state */
}

.card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Button Styling */
.btn-primary {
    background-color: #6c757d;
    /* Light grey for primary button */
    border: none;
    color: #000000;
    /* Black text */
}

.btn-primary:hover {
    background-color: #8a8a8a;
    /* Slightly lighter grey on hover */
    color: #ffffff;
    /* White text */
}

.btn-secondary {
    background-color: #4a4a4a;
    /* Grey for secondary button */
    border: none;
    color: #ffffff;
    /* White text */
}

.btn-secondary:hover {
    background-color: #6c757d;
    /* Lighter grey on hover */
}

/* Badge Styling */
.badge {
    background-color: #6c757d;
    /* Light grey */
    color: #000000;
    /* Black text */
}
</style>