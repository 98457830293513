<template>
    <div class="container mt-5">
        <h1 class="text-center mb-4">Two-Week Plan</h1>

        <!-- Progress Bar -->
        <div class="progress mb-4">
            <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }"
                :aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100">
                {{ progressPercentage }}%
            </div>
        </div>

        <!-- Day Cards -->
        <div v-for="day in tasks" :key="day.id" class="card mb-3">
            <div class="card-body d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0">{{ day.title }}</h5>
                <div>
                    <!-- Mark Complete Button -->
                    <button v-if="!day.completed" class="btn btn-primary me-2" @click="markComplete(day)">
                        Mark Complete
                    </button>

                    <!-- View Tasks Button -->
                    <router-link :to="'/day/' + day.id" class="btn btn-secondary">
                        View Tasks
                    </router-link>

                    <!-- Completed Badge -->
                    <span v-if="day.completed" class="badge bg-success ms-3">Completed</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, computed } from "vue";
import { tasks as taskData } from "../tasks"; // Import task data

// Reactive tasks data (synced with localStorage)
const tasks = reactive(
    JSON.parse(localStorage.getItem("tasks")) || taskData
);

// Computed progress percentage
const progressPercentage = computed(() => {
    const completedTasks = tasks.filter((task) => task.completed).length;
    return Math.round((completedTasks / tasks.length) * 100);
});

// Mark a task as complete
function markComplete(day) {
    day.completed = true;
    saveProgress(); // Save updated progress
    alert(day.positiveMessage); // Show motivational message
}

// Save tasks to localStorage
function saveProgress() {
    localStorage.setItem("tasks", JSON.stringify(tasks));
}
</script>

<style>
/* Optional: Styling for the component */
.progress-bar {
    font-weight: bold;
}

.card {
    transition: transform 0.2s;
}

.card:hover {
    transform: scale(1.02);
}
</style>