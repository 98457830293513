import { createRouter, createWebHistory } from "vue-router";
import DayCard from "./components/DayCard.vue";
import DayDetail from "./components/DayDetail.vue";

const routes = [
  { path: "/", component: DayCard },
  { path: "/day/:id", component: DayDetail, props: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
