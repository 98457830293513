export const tasks = [
  {
    id: 1,
    title: "Day 1: Acknowledge and Plan",
    completed: false,
    positiveMessage: "Great start! You're on the right path.",
    note: "",
    tasks: [
      {
        title: "Write a letter to yourself outlining your goals for the next two weeks.",
        details: "Take 15 minutes to write a heartfelt letter to yourself. Focus on why you're doing this plan and what you hope to achieve. Be specific—include emotional goals like trust, connection, or clarity.",
      },
      {
        title: "List what triggers your emotional outbursts when drunk.",
        details: "Reflect on recent situations where you felt emotionally charged after drinking. Write down the specific triggers, such as certain topics, behaviors, or feelings.",
      },
      {
        title: "Have an open, calm conversation with your wife about your plan.",
        details: "Explain your intention to follow this plan for self-improvement. Be clear that it's not about blame but growth. Keep the tone calm and invite her to share her thoughts or feelings.",
      },
    ],
  },
  {
    id: 2,
    title: "Day 2: Mental Grounding",
    completed: false,
    positiveMessage: "You're making amazing progress!",
    note: "",
    tasks: [
      {
        title: "Spend 10 minutes meditating or using a breathing app.",
        details: "Find a quiet space, sit comfortably, and focus on your breathing. If you're new to meditation, use a free app like Headspace or Calm to guide you.",
      },
      {
        title: "Write or say the affirmation: 'I am in control of my emotions, even when vulnerable.'",
        details: "Write this affirmation in your journal or say it aloud three times. Reflect on what being in control of your emotions looks like for you.",
      },
    ],
  },
  {
    id: 3,
    title: "Day 3: Alcohol-Free Day",
    completed: false,
    positiveMessage: "Well done! Keep up the commitment.",
    note: "",
    tasks: [
      {
        title: "Avoid alcohol completely today.",
        details: "Plan alternative activities for times you’d usually drink. For example, drink tea or sparkling water during dinner or socialize with friends without alcohol.",
      },
      {
        title: "Write down three positive moments you’ve shared with your wife recently.",
        details: "Think back to happy moments with your wife. Write about what made these moments special and how they made you feel connected.",
      },
    ],
  },
  {
    id: 4,
    title: "Day 4: Visualization and Reframing",
    completed: false,
    positiveMessage: "You’re building great habits!",
    note: "",
    tasks: [
      {
        title: "Spend 5 minutes visualizing a scenario where you feel insecure and mentally reframe it.",
        details: "Close your eyes and picture a past situation where you felt insecure. Now imagine handling it differently, with confidence and calmness. Focus on how you’d like to respond next time.",
      },
      {
        title: "Write down one thought to reframe today.",
        details: "Choose a negative or unhelpful thought you've had recent  ly (e.g., 'I can’t trust anyone'). Rewrite it positively (e.g., 'Trust can grow with effort and time').",
      },
    ],
  },
  {
    id: 5,
    title: "Day 5: Self-Awareness During Drinking",
    completed: false,
    positiveMessage: "Reflection leads to growth—great work!",
    note: "",
    tasks: [
      {
        title: "If drinking socially, track your emotions in a notebook or memo.",
        details: "Bring a small notebook or use your phone to jot down how you feel before, during, and after drinking. Look for patterns or emotional changes.",
      },
      {
        title: "Repeat the mantra: 'Pause, breathe, redirect' whenever emotions rise.",
        details: "When you feel emotions bubbling up, take a deep breath, pause for 10 seconds, and think of a positive way to redirect your thoughts or behavior.",
      },
    ],
  },
  {
    id: 6,
    title: "Day 6: Reconnect Through Positive Actions",
    completed: false,
    positiveMessage: "Connection makes you stronger together!",
    note: "",
    tasks: [
      {
        title: "Plan an activity with your wife that fosters connection (e.g., a walk or watching a movie).",
        details: "Choose something simple and enjoyable for both of you. Make it about spending quality time, like taking a stroll or cooking dinner together.",
      },
      {
        title: "Reflect on what you enjoyed about the moment.",
        details: "After the activity, take 5 minutes to write about what you enjoyed and how it made you feel closer to your wife.",
      },
    ],
  },
  {
    id: 7,
    title: "Day 7: Emotional Release",
    completed: false,
    positiveMessage: "You’ve made it through the first week—awesome!",
    note: "",
    tasks: [
      {
        title: "Do something physical to release pent-up emotions (e.g., exercise or BJJ training).",
        details: "Choose a physical activity you enjoy or want to try. It could be a run, a workout, or practicing Brazilian Jiu-Jitsu. Focus on letting go of any tension or frustration.",
      },
      {
        title: "Write down one thing to forgive your wife and one thing to forgive yourself for.",
        details: "Think about something your wife did that still lingers in your mind and forgive her. Then, forgive yourself for something you regret. Write these down to solidify the process.",
      },
    ],
  },
  {
    id: 8,
    title: "Day 8: Gratitude Day",
    completed: false,
    positiveMessage: "Gratitude opens doors to healing!",
    note: "",
    tasks: [
      {
        title: "Write down five things you’re grateful for about your wife and relationship.",
        details: "List specific things, such as her kindness, patience, or the way she supports you. Focus on the positives.",
      },
      {
        title: "Share at least one of them with her.",
        details: "Choose a quiet moment to share something you're grateful for about her. Be sincere and specific.",
      },
    ],
  },
  {
    id: 9,
    title: "Day 9: Role Reversal",
    completed: false,
    positiveMessage: "Empathy creates understanding—great job!",
    note: "",
    tasks: [
      {
        title: "Write about how your wife might be feeling during this process.",
        details: "Put yourself in her shoes and imagine her perspective. Consider what emotions or challenges she might be facing.",
      },
      {
        title: "Consider what she needs from you to heal.",
        details: "Write down a few actions you can take to support her, like listening more, showing appreciation, or spending quality time together.",
      },
    ],
  },
  {
    id: 10,
    title: "Day 10: Set Relationship Goals",
    completed: false,
    positiveMessage: "Goal-setting is key—keep it up!",
    note: "",
    tasks: [
      {
        title: "Write down three things to improve in your relationship and share them with your wife.",
        details: "Be honest about what you want to work on, like better communication, more date nights, or resolving conflicts calmly.",
      },
      {
        title: "Ask her to share her goals too.",
        details: "Encourage her to share what she’d like to see improve. Listen without judgment and discuss how you can support each other.",
      },
    ],
  },
  {
    id: 11,
    title: "Day 11: Mindful Drinking Practice",
    completed: false,
    positiveMessage: "Mindfulness leads to control—fantastic work!",
    note: "",
    tasks: [
      {
        title: "Set a drink limit if drinking today (e.g., two drinks max).",
        details: "Decide on a clear limit before you start drinking and stick to it. Avoid situations that may pressure you to exceed your limit.",
      },
      {
        title: "Use grounding techniques like pausing and breathing to stay in control.",
        details: "If you feel tempted to drink more or lose control, pause, take a deep breath, and remind yourself of your goals.",
      },
    ],
  },
  {
    id: 12,
    title: "Day 12: Trust Exercise",
    completed: false,
    positiveMessage: "Trust is growing stronger—well done!",
    note: "",
    tasks: [
      {
        title: "Practice an act of trust-building (e.g., share a vulnerability with your wife).",
        details: "Open up about something you’ve been struggling with or feeling. Trust her to listen and respond with care.",
      },
      {
        title: "Reflect on how this felt and what it taught you.",
        details: "Write down how it felt to be vulnerable and how your wife responded. Reflect on how this strengthens your bond.",
      },
    ],
  },
  {
    id: 13,
    title: "Day 13: Future Focus",
    completed: false,
    positiveMessage: "You’re paving the way for a bright future!",
    note: "",
    tasks: [
      {
        title: "Visualize a future where trust and love are restored.",
        details: "Close your eyes and picture a happy, trusting relationship. Imagine how you’d like things to be in 6 months or a year.",
      },
      {
        title: "Write down steps you’ll continue to take to make this a reality.",
        details: "List specific actions you’ll take, like regular check-ins, showing appreciation, or practicing mindfulness.",
      },
    ],
  },
  {
    id: 14,
    title: "Day 14: Celebrate Progress",
    completed: false,
    positiveMessage: "Celebrate your journey—you’ve done it!",
    note: "",
    tasks: [
      {
        title: "Reflect on the past two weeks and what you’ve learned.",
        details: "Write about the changes you’ve made, the challenges you faced, and how you’ve grown emotionally.",
      },
      {
        title: "Share your reflections with your wife and discuss your next steps together.",
        details: "Sit down with your wife and talk about what you’ve learned. Discuss how you can continue building trust and connection.",
      },
    ],
  },
];
